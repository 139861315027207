<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Debit Note Claim Report" style="z-index:10">
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Operating Unit / Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="operatingUnit"
                        :options="optionOUs"
                        :multiple="true"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="ID"
                        label="Name"
                        @input="onChange"
                        @select="onSelect"
                    >

                       <template template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.Name }}</span>
                            </span>
                        </template>

                         <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.SelectName }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Supplier</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="supplier"
                        :options="optionSupplier"
                        :multiple="true"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="Name"
                        label="Code"
                        @input="onChangeSupplier"
                        :custom-label="labelSupplier"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.Code }} - {{ props.option.Name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.Code }} - {{ props.option.Name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Item</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="item.selected"
                        :options="item.options"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        label="name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>DN Date</span>&nbsp;
                    <feather-icon title="Reset" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('date')"/>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <date-range-picker
                        style="min-height: 40px"
                        class="w-full"
                        ref="picker"
                        opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        v-model="filterDate"
                        :linkedCalendars="true"
                    >
                        <template v-slot:input="picker" style="min-width: 350px">
                            {{ datePickerDateFormat(picker.startDate) }} -
                            {{ datePickerDateFormat(picker.endDate) }}
                        </template>
                    </date-range-picker>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Posting Date</span>&nbsp;
                    <feather-icon title="Reset" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('posting')"/>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <date-range-picker
                        style="min-height: 40px"
                        class="w-full"
                        ref="picker"
                        opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        v-model="filterPostingDate"
                        :linkedCalendars="true"
                    >
                        <template v-slot:input="picker" style="min-width: 350px">
                            {{ datePickerDateFormat(picker.startDate) }} -
                            {{ datePickerDateFormat(picker.endDate) }}
                        </template>
                    </date-range-picker>
                </div>
            </div>
            
            <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full">
                <span>Open Key Date</span>
                <!-- <feather-icon title="Reset" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('openkeydate')"/> -->
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                <datepicker :inline="false" v-model="openKeyDate"></datepicker>
                </div>
            </div>

            <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>SOA Number</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full"  v-model="soa_number" />
                </div>
            </div>
            
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-button class="ml-4 mt-2 vx-col sm:w-2/4" color="primary" icon-pack="feather" icon="icon-prin" @click="handleDrawTable()">Get Data</vs-button>
                </div>
            </div>
        </vx-card>
        <vx-card title="" style="z-index:9">
            <vs-tabs :color="colorx" ref="tabs">
                <vs-tab @click="changeTab(1)" label="Debit Note Claim Report">
                    <div class="con-tab-ejemplo">
                        <br>
                        <monitoring :operatingUnit="operatingUnit" :item="item.selected" :territories="TerritoryCodes" :supplier="supplierCode" v-on:selectSalesOrder="updateSelectedSalesOrder" v-on:redirectTab="redirectTab" :filterDate="filterDate" :filterPostingDate="filterPostingDate"  :draw="draw" :openKeyDate="this.openKeyDate" :soa_number="this.soa_number"></monitoring>
                    </div>
                </vs-tab>
                <vs-tab label="Export">
                    <div class="tab-text">
                        <data-table-export></data-table-export>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import monitoring from "./monitoring/index.vue";
import DataTableExport from "./monitoring/DataTableExport.vue";
import DateRangePicker from "vue2-daterange-picker";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
    components: {
        monitoring,
        Datepicker,
        DateRangePicker,
        DataTableExport
    },
    mounted() {
        this.getOptionOU();
        this.getOptionSupplier();
        
        const today = new Date();
        this.filterDate.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.filterDate.endDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
        this.filterPostingDate.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.filterPostingDate.endDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
    },
    data: () => ({
        tabColor:['success', 'primary', 'warning', 'info', 'success', 'rgb(62, 201, 214)'],
        tabIndex: 0,
        draw: 0,
        openKeyDate: new Date(),
        soa_number: null,
        supplier: {},
        supplierCode: [{}],
        item: {
          options: ["All", "Open", "Close"],
          selected: "All"
        },
        optionSupplier:[{}],
        colorx: "success",
        selectedSO: {},
        TerritoryCodes: [{}], 
        operatingUnit: {
            OperatingUnitTerritory: {
                Territory: {
                    code: ""
                }
            }
        },
        optionOUs: [{
            Code: "-",
            ID: "0",
            OperatingUnitTerritory: {
                Code: "All",
                Territory: {
                    ID: 0, code: "All", name: "All"
                }
            }
        }],
        optionOU: [{
            OperatingUnitTerritory: {
                Territory: {
                    code: ""
                }
            }
        }],
        filterDate: {
            startDate: null,
            endDate: null,
        },
        filterPostingDate: {
            startDate: null,
            endDate: null,
        },
    }),
    watch: {
    },
    methods: {
        handleDrawTable(){
            
             this.draw++;
        },
        changeTab(index) {
            this.tabIndex = index;
            this.colorx = this.tabColor[index];
        },
        datePickerDateFormat(date) {
            let a = null;
            if (date != null) {
                a = moment(date).format("dddd, MMMM Do YYYY");
            }
            return a;
        },
        resetDateFilter(name) {
            if(name=="date") {
                this.filterDate.startDate = null;
                this.filterDate.endDate = null;
            } else if(name=="posting") {
                this.filterPostingDate.startDate = null;
                this.filterPostingDate.endDate = null;
            } else if(name=="openkeydate") {
                this.openKeyDate = null;
            }
        },
        labelSupplier ({ Code, Name }) {
            return `${Code} - ${Name}`
        },
        getOptionOU() {
            // console.log(this.optionOUs)
            this.$http.get("/api/v1/master/operating-unit-territory").then(resp => {
                this.optionOU = resp.data.records;
                let datas = resp.data.records
                this.optionOUs = []
                this.optionOUs.push({
                    CodeOu: "AllOu",
                    CodeOuTerr: "AllOuTerr",
                    CodeTerr: "AllCode",
                    ID: "zero",
                    IDTerr: "zeroTerr",
                    SelectName: "All OU/Territory",
                    Name: "All",
                })
                for (var idx = 0; idx < resp.data.records.length; idx++) {
                    this.optionOUs.push({
                        CodeOu: datas[idx].Territory.code,
                        CodeOuTerr: datas[idx].Territory.code,
                        CodeTerr: datas[idx].Territory.code,
                        ID: datas[idx].Territory.ID,
                        IDTerr: datas[idx].Territory.ID, code: datas[idx].Territory.Code,
                        SelectName: " (" + datas[idx].Territory.code + ") " + datas[idx].Territory.name,
                        Name: " (" + datas[idx].Territory.code + ") " + datas[idx].Territory.name
                    })
                }
                this.operatingUnit = this.optionOUs[0];
            });
        },
        getOptionSupplier() {
            this.$http.get("/api/v1/master/suppliers").then(
                resp => {
                    if(resp.code == 200){
                        this.optionSupplier = []
                        this.optionSupplier.push({ID: 0, Code:'All', Name:'All'})
                        let data = resp.data.records
                        for(var idx = 0;idx<data.length;idx++){
                            this.optionSupplier.push(data[idx])
                        }
                        this.supplier = this.optionSupplier[0];
                        console.log("this.supplier", this.supplier)
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        updateSelectedSalesOrder(salesOrder) {
            this.selectedSO = salesOrder;
            this.changeTab(1);
            // console.log("selected", this.selectedSO);
        },
        redirectTab(index) {
            this.$refs.tabs.activeChild(index);
        },
        onChange(value) {
            let keys = Object.keys(value)
            let data = []
            keys.forEach(function (val, i, vals) {
                if (value[i].IDTerr != "zeroTerr" && value[i].IDTerr != undefined) {
                    data.push({ ID: value[i].IDTerr, Code: value[i].CodeTerr })
                } else {
                    data = [{
                        ID: 0, Code: "All"
                    }]
                }
            });
            this.TerritoryCodes = data
        },
        onChangeSupplier(value) {
            let keys = Object.keys(value)
            let data = []
            console.log("value", value)
            keys.forEach(function (val, i, vals) {
                if (value[i].ID != 0) {
                    data.push({ ID: value[i].ID, Code: value[i].Code, Name: value[i].Name })
                } else {
                    data = [{
                        ID: 0, Code: "All", Name: "All"
                    }]
                }
            });
            this.supplierCode = data
        },
        onSelect(val, i) {

            if (val.ID == "zero") {
                this.getOptionOU
            }
            console.log("OnSelect")
            console.log(val.ID)
            console.log(i)
        }
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>